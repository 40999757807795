// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-front-page-js": () => import("../src/templates/front_page.js" /* webpackChunkName: "component---src-templates-front-page-js" */),
  "component---src-templates-colaborare-js": () => import("../src/templates/colaborare.js" /* webpackChunkName: "component---src-templates-colaborare-js" */),
  "component---src-templates-redirect-js": () => import("../src/templates/redirect.js" /* webpackChunkName: "component---src-templates-redirect-js" */),
  "component---src-templates-donate-js": () => import("../src/templates/donate.js" /* webpackChunkName: "component---src-templates-donate-js" */),
  "component---src-templates-developers-js": () => import("../src/templates/developers.js" /* webpackChunkName: "component---src-templates-developers-js" */),
  "component---src-templates-page-js": () => import("../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-team-js": () => import("../src/templates/team.js" /* webpackChunkName: "component---src-templates-team-js" */)
}

